var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "Transition__fade" } }, [
    _vm.dataLoaded
      ? _c(
          "div",
          {
            class: [
              "DashboardClientNotifications",
              {
                "DashboardClientNotifications--mobile": _vm.mobile.isMobile
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "DashboardClientNotifications__stats row row--align-center"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "DashboardClientNotifications__invoice-stat-outside-wrapper width-100"
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "DashboardClientNotifications__invoice-stat-wrapper clickable",
                          {
                            "DashboardClientNotifications__invoice-stat-wrapper--active":
                              _vm.activeInvoices === "chargebacks"
                          }
                        ],
                        attrs: { tabindex: "0" },
                        on: { click: _vm.viewChargebackInvoices }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "fc-red uppercase mb-16 center" },
                          [_vm._v("Upcoming Chargebacks")]
                        ),
                        _c("label", { staticClass: "fs-36 mb-16" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.chargebacksCount || 0) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "label",
                          { staticClass: "fc-light uppercase mb-11" },
                          [_vm._v("Invoices")]
                        ),
                        _c("label", { staticClass: "fs-24" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD"
                                }).format(_vm.chargebacksAmount / 100)
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "DashboardClientNotifications__invoice-stat-outside-wrapper width-100"
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "DashboardClientNotifications__invoice-stat-wrapper clickable",
                          {
                            "DashboardClientNotifications__invoice-stat-wrapper--active":
                              _vm.activeInvoices === "paperwork"
                          }
                        ],
                        attrs: { tabindex: "0" },
                        on: { click: _vm.viewPaperworkInvoices }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "fc-red uppercase mb-16 center" },
                          [_vm._v("Paperwork Issues")]
                        ),
                        _c("label", { staticClass: "fs-36 mb-16" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.paperworkCount || 0) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "label",
                          { staticClass: "fc-light uppercase mb-11" },
                          [_vm._v("Invoices")]
                        ),
                        _c("label", { staticClass: "fs-24" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD"
                                }).format(_vm.paperworkAmount / 100)
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "DashboardClientNotifications__invoice-stat-outside-wrapper width-100"
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "DashboardClientNotifications__invoice-stat-wrapper clickable",
                          {
                            "DashboardClientNotifications__invoice-stat-wrapper--active":
                              _vm.activeInvoices === "originals"
                          }
                        ],
                        attrs: { tabindex: "0" },
                        on: { click: _vm.viewOriginalsInvoices }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "fc-red uppercase mb-16 center" },
                          [_vm._v("Originals Required")]
                        ),
                        _c("label", { staticClass: "fs-36 mb-16" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.originalsCount || 0) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "label",
                          { staticClass: "fc-light uppercase mb-11" },
                          [_vm._v("Invoices")]
                        ),
                        _c("label", { staticClass: "fs-24" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD"
                                }).format(_vm.originalsAmount / 100)
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ]
                )
              ]
            ),
            _c("hr", { staticClass: "Divider mb-23" }),
            _c(
              "transition",
              { attrs: { name: "Transition__fade" } },
              [
                _vm.invoices.length
                  ? _c(
                      "base-invoice-table",
                      {
                        key: "invoice-table",
                        attrs: {
                          status: _vm.invoicesStatus,
                          "user-is-client": _vm.userIsClient
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "Table__slot-content column" },
                          [
                            _c("label", { staticClass: "fs-16 fw-med" }, [
                              _vm._v(_vm._s(_vm.invoicesHeaderInfo.heading))
                            ]),
                            _c("label", { staticClass: "fc-light fs-14" }, [
                              _vm._v(_vm._s(_vm.invoicesHeaderInfo.subheading))
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("base-pagination", {
              key: "invoice-pagination",
              attrs: {
                "item-count": _vm.selectedInvoicesCount,
                page: _vm.page,
                "request-limit": 25
              },
              on: { paginate: _vm.paginate }
            }),
            !_vm.invoices.length
              ? [
                  _c("div", { staticClass: "row" }, [
                    _c("label", { staticClass: "mt-30" }, [
                      _vm._v("No invoices found")
                    ])
                  ])
                ]
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }