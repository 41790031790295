<template>
  <transition name="Transition__fade">
    <div
      v-if="dataLoaded"
      :class="['DashboardClientNotifications', {
        'DashboardClientNotifications--mobile': mobile.isMobile
      }]"
    >
      <!-- Notification Cards and results -->
      <div class="DashboardClientNotifications__stats row row--align-center">
        <!-- UPCOMING CHARGEBACKS -->
        <div class="DashboardClientNotifications__invoice-stat-outside-wrapper width-100">
          <div
            @click="viewChargebackInvoices"
            :class="[
              'DashboardClientNotifications__invoice-stat-wrapper clickable',
              {
                'DashboardClientNotifications__invoice-stat-wrapper--active':
                  activeInvoices === 'chargebacks'
              }
            ]"
            tabindex="0"
          >
            <label class="fc-red uppercase mb-16 center">Upcoming Chargebacks</label>
            <label class="fs-36 mb-16">
              {{ chargebacksCount || 0 }}
            </label>
            <label class="fc-light uppercase mb-11">Invoices</label>
            <label class="fs-24">
              {{
                Intl
                  .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(chargebacksAmount / 100)
              }}
            </label>
          </div>
        </div>

        <!-- PAPERWORK ISSUES -->
        <div class="DashboardClientNotifications__invoice-stat-outside-wrapper width-100">
          <div
            @click="viewPaperworkInvoices"
            :class="[
              'DashboardClientNotifications__invoice-stat-wrapper clickable',
              {
                'DashboardClientNotifications__invoice-stat-wrapper--active':
                  activeInvoices === 'paperwork'
              }
            ]"
            tabindex="0"
          >
            <label class="fc-red uppercase mb-16 center">Paperwork Issues</label>
            <label class="fs-36 mb-16">
              {{ paperworkCount || 0 }}
            </label>
            <label class="fc-light uppercase mb-11">Invoices</label>
            <label class="fs-24">
              {{
                Intl
                  .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(paperworkAmount / 100)
              }}
            </label>
          </div>
        </div>

        <!-- ORIGINALS REQUIRED -->
        <div class="DashboardClientNotifications__invoice-stat-outside-wrapper width-100">
          <div
            @click="viewOriginalsInvoices"
            :class="[
              'DashboardClientNotifications__invoice-stat-wrapper clickable',
              {
                'DashboardClientNotifications__invoice-stat-wrapper--active':
                  activeInvoices === 'originals'
              }
            ]"
            tabindex="0"
          >
            <label class="fc-red uppercase mb-16 center">Originals Required</label>
            <label class="fs-36 mb-16">
              {{ originalsCount || 0 }}
            </label>
            <label class="fc-light uppercase mb-11">Invoices</label>
            <label class="fs-24">
              {{
                Intl
                  .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(originalsAmount / 100)
              }}
            </label>
          </div>
        </div>
      </div>

      <hr class="Divider mb-23">

      <transition name="Transition__fade">
        <base-invoice-table
          v-if="invoices.length"
          key="invoice-table"
          :status="invoicesStatus"
          :user-is-client="userIsClient"
        >
          <div class="Table__slot-content column">
            <label class="fs-16 fw-med">{{ invoicesHeaderInfo.heading }}</label>
            <label class="fc-light fs-14">{{ invoicesHeaderInfo.subheading }}</label>
          </div>
        </base-invoice-table>
      </transition>

      <base-pagination
        @paginate="paginate"
        key="invoice-pagination"
        :item-count="selectedInvoicesCount"
        :page="page"
        :request-limit="25"
      />

      <template v-if="!invoices.length">
        <div class="row">
          <label class="mt-30">No invoices found</label>
        </div>
      </template>
    </div>
  </transition>
</template>

<script>
/* eslint-disable max-len */
// Packages
import moment from 'moment'
// Helpers
import { Invoice } from '../../utils/api'
// Components
import BaseInvoiceTable from '../../components/base-invoice-table.vue'
import BasePagination from '../../components/base-pagination.vue'

export default {
  name: 'DashboardClientNotifications',

  components: {
    BaseInvoiceTable,
    BasePagination,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  async created () {
    await this.getInvoiceStats()
    await this.viewChargebackInvoices()
    this.dataLoaded = true
  },

  data () {
    return {
      activeInvoices: 'chargebacks',
      dataLoaded: false,
      chargebacksCount: null,
      chargebacksAmount: null,
      originalsCount: null,
      originalsAmount: null,
      page: 1,
      paperworkCount: null,
      paperworkAmount: null,
      selectedInvoices: null,
      selectedInvoicesCount: null,
    }
  },

  computed: {
    invoices () {
      return this.$store.getters.invoices.invoices
    },

    invoicesHeaderInfo () {
      if (this.activeInvoices === 'chargebacks') {
        return {
          heading: 'Upcoming Chargebacks',
          subheading: 'Invoices that are at risk of chargeback at 60 days',
        }
      }
      if (this.activeInvoices === 'originals') {
        return {
          heading: 'Originals Required',
          subheading: 'Invoices that require original paperwork to be mailed to the broker',
        }
      }
      if (this.activeInvoices === 'paperwork') {
        return {
          heading: 'Paperwork Issues',
          subheading: 'Invoices with paperwork issues that require your attention',
        }
      }
      return null
    },

    invoicesStatus () {
      if (!this.invoices.length) return null
      return this.invoices[0].status
    },

    mobile () {
      return this.$store.getters.mobile
    },
  },

  methods: {
    async getChargebacks () {
      // Upcoming chargebacks are an invoice that is >= 60 days outstanding
      this.progressStart()

      const qualifiedChargebackDate = moment().subtract(60, 'days').toDate()
      this.CLog('qualified chargeback date:', qualifiedChargebackDate)
      try {
        const invoices = (await Invoice.queryList({
          lt___approved_date: qualifiedChargebackDate,
          client_id: this.id,
          limit: 25,
          offset: (this.page - 1) * 25,
          sort_by: 'approved_date',
          sort_direction: 'asc',
          status: 'approved'
        })).data

        this.$store.commit('STORE_INVOICES', invoices)
        this.selectedInvoicesCount = invoices.count
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Client Notifications "Get Chargebacks"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            qualifiedChargebackDate,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting upcoming chargebacks' })
      }
    },

    async getChargebackStats () {
      const qualifiedChargebackDate = moment().subtract(60, 'days').toDate()
      try {
        const data = (await Invoice.queryList({
          lt___approved_date: qualifiedChargebackDate,
          client_id: this.id,
          limit: 1,
          offset: 0,
          status: 'approved'
        })).data

        this.chargebacksAmount = data.facets_stats ? data.facets_stats.total_amount.sum : 0
        this.chargebacksCount = data.count
      }
      catch (error) {
        console.error(error)
        this.captureSentryEvent(
          'Dashboard Client Notifications "Get Chargeback Stats"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            qualifiedChargebackDate,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting chargeback statistics' })
      }
    },

    async getInvoiceStats () {
      this.progressStart()

      try {
        await this.getChargebackStats()
        await this.getOriginalsStats()
        await this.getPaperworkStats()
        this.progressFinish()
      }
      catch (error) {
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting your invoice statistics' })
      }
    },

    async getOriginalsRequired () {
      this.progressStart()

      try {
        const invoices = (await Invoice.queryList({
          client_id: this.id,
          originals_required: true,
          status: 'approved'
        })).data

        this.$store.commit('STORE_INVOICES', invoices)
        this.selectedInvoicesCount = invoices.count
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Client Notifications "Get Originals Required"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting paperwork issues' })
      }
    },

    async getOriginalsStats () {
      try {
        const data = (await Invoice.queryList({
          client_id: this.id,
          originals_required: true,
          status: 'approved'
        })).data

        this.originalsAmount = data.facets_stats ? data.facets_stats.total_amount.sum : 0
        this.originalsCount = data.count
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Client Notifications "Get Originals Stats"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting original statistics' })
      }
    },

    async getPaperworkIssues () {
      this.progressStart()
      // looking for invoices w/ approved_missing paperwork or approved_unreadable
      try {
        const invoices = (await Invoice.queryList({
          client_id: this.id,
          paperwork_issues: true,
          status: 'approved'
        })).data

        this.$store.commit('STORE_INVOICES', invoices)
        this.selectedInvoicesCount = invoices.count
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Client Notifications "Get Paperwork Issues"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting paperwork issues' })
      }
    },

    async getPaperworkStats () {
      try {
        const data = (await Invoice.queryList({
          client_id: this.id,
          paperwork_issues: true,
          status: 'approved'
        })).data

        this.paperworkAmount = data.facets_stats ? data.facets_stats.total_amount.sum : 0
        this.paperworkCount = data.count
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Client Notifications "Get Paperwork Stats"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting paperwork statistics' })
      }
    },

    paginate (e) {
      this.page = e

      if (this.selectedInvoices === 'chargebacks') {
        this.getChargebacks()
      }
      if (this.selectedInvoices === 'originals') {
        this.getOriginalsRequired()
      }
      if (this.selectedInvoices === 'paperwork') {
        this.getPaperworkIssues()
      }
    },

    async viewChargebackInvoices () {
      this.activeInvoices = 'chargebacks'
      this.page = 1
      this.selectedInvoices = 'chargebacks'
      await this.getChargebacks()
    },

    async viewOriginalsInvoices () {
      this.activeInvoices = 'originals'
      this.page = 1
      this.selectedInvoices = 'originals'
      await this.getOriginalsRequired()
    },

    async viewPaperworkInvoices () {
      this.activeInvoices = 'paperwork'
      this.page = 1
      this.selectedInvoices = 'paperwork'
      await this.getPaperworkIssues()
    },
  },
}
</script>

<style lang="sass">
.DashboardClientNotifications
  $block: &
  background-color: $white

  .BaseInvoiceTable
    margin: 0

  .BasePagination
    margin-top: rem(41px)

  &__invoice-stat-outside-wrapper
    border-right: rem(1px) solid $borders
    padding: 0 rem(23px)

    &:first-child
      padding-left: 0

    &:last-child
      border-right: none
      padding-right: 0

  &__invoice-stat-wrapper
    +flex(column, $align: center, $justify: center)
    border: $border
    border-radius: rem(8px)
    height: rem(228px)
    padding: rem(21px)
    transition: border-color .15s $ease-in-quad

    &:hover,
    &:focus
      box-shadow: $shadow-a, $shadow-b
      outline: none

    &--active
      border: rem(1px) solid $branding

  &__invoice-stat-wrapper-separator
    background-color: $borders
    height: 100%
    width: rem(1px)

  &__stats
    margin-bottom: rem(37px)

  &--mobile
    margin-top: rem(37px)
</style>